import { z } from 'zod';
import { intervalEnum, timeAttributeEnum, timeDefinitionEnum, timeDefinitionEnumLower } from './enums';
import { coordinatesSchema, matchingOverviewValueSchema, valueUnitMetricSchema } from './shared';

export const consumerAllocationBreakdownValueSchema = z.object({
  label: z.string(),
  value: z.number(),
});
export type ConsumerAllocationBreakdownValueSchema = z.infer<typeof consumerAllocationBreakdownValueSchema>;

export const consumerOverTimeMatchingSchema = z.object({
  consumerKpi: z.object({
    overTimeMatching: z.object({
      unit: z.string(),
      interval: intervalEnum,
      data: z.array(
        z.object({
          date: z.string(),
          consumption: z.number(),
          unmatchedConsumption: z.number(),
          matchingAllocationsBreakdown: z.array(consumerAllocationBreakdownValueSchema),
          excessAllocationsBreakdown: z.array(consumerAllocationBreakdownValueSchema),
        }),
      ),
    }),
  }),
});
export type ConsumerOverTimeMatchingData = z.infer<
  typeof consumerOverTimeMatchingSchema
>['consumerKpi']['overTimeMatching'];
export type ConsumerOverTimeMatching = z.infer<typeof consumerOverTimeMatchingSchema>;

export const consumerLongRunAverageMatchingSchema = z.object({
  consumerKpi: z.object({
    longRunAverageMatching: z.object({
      unit: z.string(),
      timeAttribute: timeAttributeEnum,
      data: z.array(
        z.object({
          timeIndex: z.string(),
          consumption: z.number(),
          unmatchedConsumption: z.number(),
          matchingAllocationsBreakdown: z.array(consumerAllocationBreakdownValueSchema),
          excessAllocationsBreakdown: z.array(consumerAllocationBreakdownValueSchema),
        }),
      ),
    }),
  }),
});
export type ConsumerLongRunAverageMatchingData = z.infer<
  typeof consumerLongRunAverageMatchingSchema
>['consumerKpi']['longRunAverageMatching'];
export type ConsumerLongRunAverageMatching = z.infer<typeof consumerLongRunAverageMatchingSchema>;

export const consumerMatchingDevicesSchema = z.object({
  consumerKpi: z.object({
    matchingDevices: z.object({
      devices: z.array(
        z.object({
          assetName: z.string(),
          coordinates: coordinatesSchema.nullable(),
          country: z.string(),
          deviceTechnology: z.string(),
          percentageMatchingGenerationAllocated: z.number(),
        }),
      ),
      total: z.object({
        percentageMatchingGenerationAllocated: z.number(),
      }),
    }),
  }),
});
export type ConsumerMatchingDevices = z.infer<typeof consumerMatchingDevicesSchema>;
export type ConsumerMatchingDevicesData = z.infer<
  typeof consumerMatchingDevicesSchema
>['consumerKpi']['matchingDevices'];

export const consumerOverviewRequestSchema = z.object({
  startDateTime: z.string(),
  endDateTime: z.string(),
  consumerId: z.string(),
  timeDefinitions: z.array(timeDefinitionEnum).nullable(),
});

export type ConsumerOverviewRequest = z.infer<typeof consumerOverviewRequestSchema>;

export const consumerOverviewSchema = z.object({
  consumption: valueUnitMetricSchema,
  current: z.array(
    z.object({
      excess_generation_allocated: valueUnitMetricSchema,
      matching_generation_allocated: valueUnitMetricSchema,
      matching_generation_allocated_percentage: z.number(),
      time_definition: timeDefinitionEnumLower,
      total_generation_allocated: valueUnitMetricSchema,
      unmatched_consumption: valueUnitMetricSchema,
    }),
  ),
});

export type ConsumerOverview = z.infer<typeof consumerOverviewSchema>;

// Carbon Request
export const consumerCarbonRequestSchema = z.object({
  start_datetime: z.string(),
  end_datetime: z.string(),
  consumer_ids: z.array(z.string()).nullable(),
  time_definitions: z.array(timeDefinitionEnumLower).nullable(),
});

export type ConsumerCarbonRequest = z.infer<typeof consumerCarbonRequestSchema>;

// Carbon Response Schemas
export const carbonMetricSchema = z.object({
  location_based: z.number().nullable(),
  market_based: z.number().nullable(),
  impact: z.number().nullable(),
  unit: z.string().nullable(),
});

const carbonMetricsAtTimeDefinitionSchema = z.object({
  time_definition: timeDefinitionEnumLower,
  metrics: carbonMetricSchema,
});

export const consumerCarbonMetricsResponseSchema = z.array(carbonMetricsAtTimeDefinitionSchema);
export type ConsumerCarbonResponse = z.infer<typeof consumerCarbonMetricsResponseSchema>;

// Generic table schema (for both overview and carbon)
export const consumerOverviewGridRowSchema = z
  .object({
    dataType: z.string(),
    unit: z.string(),
    matchingOverviewFiveMinute: matchingOverviewValueSchema,
    matchingOverviewFifteenMinute: matchingOverviewValueSchema,
    matchingOverviewThirtyMinute: matchingOverviewValueSchema,
    matchingOverviewHourly: matchingOverviewValueSchema,
    matchingOverviewDaily: matchingOverviewValueSchema,
    matchingOverviewMonthly: matchingOverviewValueSchema,
    matchingOverviewQuarterly: matchingOverviewValueSchema,
    matchingOverviewUkCompliancePeriod: matchingOverviewValueSchema,
    matchingOverviewYearly: matchingOverviewValueSchema,
    matchingOverviewWholePeriod: matchingOverviewValueSchema,
  })
  .partial();
export const consumerOverviewGridSchema = z.array(consumerOverviewGridRowSchema);

export type ConsumerOverviewGridData = z.infer<typeof consumerOverviewGridSchema>;
export type ConsumerOverviewGridRow = z.infer<typeof consumerOverviewGridRowSchema>;

export const carbonContentSchema = z.object({
  data: z.object({
    locationBased: z.number().nullable(),
    marketBased: z.number().nullable(),
    impact: z.number().nullable(),
    unit: z.string().nullable(),
  }),
});

export const matchingOverviewSchema = z.object({
  data: z.object({
    consumption: z.number(),
    totalGenerationAllocated: z.number(),
    matchingGenerationAllocated: z.number(),
    percentageMatchingGenerationAllocated: z.number(),
    excessGenerationAllocated: z.number(),
    unmatchedConsumption: z.number(),
  }),
  unit: z.string(),
});

export const consumerKpiSchema = z.object({
  consumerKpi: z.object({
    overviewGlobalMatching: matchingOverviewSchema,
    carbonContentOverview: z.object({
      data: z.object({
        impact: z.number().nullable(),
        locationBased: z.number().nullable(),
        marketBased: z.number().nullable(),
        unit: z.string().nullable(),
      }),
    }),
  }),
});
export type ConsumerKPI = z.infer<typeof consumerKpiSchema>;
export type ConsumerKPIOverviewGlobalMatching = z.infer<
  typeof consumerKpiSchema
>['consumerKpi']['overviewGlobalMatching'];
export type ConsumerKPICarbonContent = z.infer<typeof consumerKpiSchema>['consumerKpi']['carbonContentOverview'];
