import { gql } from 'urql';

export const portfolioOverviewGlobalMatching = gql`
query portfolioKPIsQuery($startDate: DateTime!, $endDate: DateTime!, $consumerIds: [UUID!], $producerIds: [UUID!], $timeDefinition: TimeDefinition!) {
  portfolioKpi(startTime: $startDate, endTime: $endDate, consumerUuids: $consumerIds, productionDeviceIds: $producerIds, timeDefinition: $timeDefinition) {
    overviewGlobalMatching {
      unit,
      data {
        consumption,
        matchingGenerationTotal,
        matchingGenerationAllocated,
        percentageMatchingGenerationAllocated,
        percentageMatchingGenerationUnallocated,
        matchingGenerationUnallocated,
        unmatchedConsumption,
        excessGenerationTotal,
        excessGenerationUnallocated,
        excessGenerationAllocated,
        generation,
        generationAllocated,
        generationUnallocated,
      }
    }
  }
}`;

export const portfolioOverTimeMatching = gql`
query portfolioKPIsQuery($startDate: DateTime!, $endDate: DateTime!, $consumerIds: [UUID!], $producerIds: [UUID!], $timeDefinition: TimeDefinition!) {
  portfolioKpi(startTime: $startDate, endTime: $endDate, consumerUuids: $consumerIds, productionDeviceIds: $producerIds, timeDefinition: $timeDefinition) {
    overTimeMatching {
      unit,
      interval,
      data {
        date
        consumption,
        matchingGenerationAllocated,
        matchingGenerationUnallocated,
        unmatchedConsumption,
        excessGenerationUnallocated,
        excessGenerationAllocated
      }
    }
  }
}`;

export const portfolioLongRunAverageMatching = gql`
query portfolioKPIsQuery($startDate: DateTime!, $endDate: DateTime!, $consumerIds: [UUID!], $producerIds: [UUID!], $timeDefinition: TimeDefinition!) {
  portfolioKpi(startTime: $startDate, endTime: $endDate, consumerUuids: $consumerIds, productionDeviceIds: $producerIds, timeDefinition: $timeDefinition) {
    longRunAverageMatching {
      unit,
      timeAttribute,
      data {
        timeIndex,
        consumption,
        matchingGenerationAllocated,
        matchingGenerationUnallocated,
        unmatchedConsumption,
        excessGenerationUnallocated,
        excessGenerationAllocated
      }
    }
  }
}`;

export const getPortfolioCompare = gql`
query portfolioCompareQuery($startDate: DateTime!, $endDate: DateTime!, $consumerIds: [UUID!], $producerIds: [UUID!]) {
  portfolioKpi(startTime: $startDate, endTime: $endDate, consumerUuids: $consumerIds, productionDeviceIds: $producerIds) {
    overviewGlobalMatchingHourly {
      data {
        consumption
        matchingGenerationTotal
        matchingGenerationAllocated
        matchingGenerationUnallocated
        generation
        generationAllocated
        generationUnallocated
        percentageMatchingGenerationAllocated
        percentageMatchingGenerationUnallocated
        excessGenerationTotal
        excessGenerationAllocated
        excessGenerationUnallocated
        unmatchedConsumption
      }
      unit
    },
    overviewGlobalMatchingDaily {
      data {
        consumption
        matchingGenerationTotal
        matchingGenerationAllocated
        matchingGenerationUnallocated
        generation
        generationAllocated
        generationUnallocated
        percentageMatchingGenerationAllocated
        percentageMatchingGenerationUnallocated
        excessGenerationTotal
        excessGenerationAllocated
        excessGenerationUnallocated
        unmatchedConsumption
      }
      unit
    },
    overviewGlobalMatchingMonthly {
      data {
        consumption
        matchingGenerationTotal
        matchingGenerationAllocated
        matchingGenerationUnallocated
        generation
        generationAllocated
        generationUnallocated
        percentageMatchingGenerationAllocated
        percentageMatchingGenerationUnallocated
        excessGenerationTotal
        excessGenerationAllocated
        excessGenerationUnallocated
        unmatchedConsumption
      }
      unit
    },
    overviewGlobalMatchingYearly {
      data {
        consumption
        matchingGenerationTotal
        matchingGenerationAllocated
        matchingGenerationUnallocated
        generation
        generationAllocated
        generationUnallocated
        percentageMatchingGenerationAllocated
        percentageMatchingGenerationUnallocated
        excessGenerationTotal
        excessGenerationAllocated
        excessGenerationUnallocated
        unmatchedConsumption
      }
      unit
    },
    overviewGlobalMatchingUkCompliancePeriod {
      data {
        consumption
        matchingGenerationTotal
        matchingGenerationAllocated
        matchingGenerationUnallocated
        generation
        generationAllocated
        generationUnallocated
        percentageMatchingGenerationAllocated
        percentageMatchingGenerationUnallocated
        excessGenerationTotal
        excessGenerationAllocated
        excessGenerationUnallocated
        unmatchedConsumption
      }
      unit
    }
  }
}`;
