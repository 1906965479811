import { gql } from 'urql';

export const matchingAverageTimeAttribute = {
  Hourly: 'hour',
  Daily: 'dayOfWeek',
  Monthly: 'month',
  Yearly: 'year',
  CompliancePeriod: 'year',
} as const;

export const consumerMatchingOverviewQuery = gql`
    query consumerMatchingOverview($startDate: DateTime!, $endDate: DateTime!, $consumerIds: [UUID!], $timeDefinition: TimeDefinition!) {
      consumerKpi(startTime: $startDate, endTime: $endDate, consumerUuids: $consumerIds, timeDefinition: $timeDefinition) {
        overviewGlobalMatching {
          unit,
          data {
            consumption,
            totalGenerationAllocated,
            excessGenerationAllocated,
            matchingGenerationAllocated,
            percentageMatchingGenerationAllocated,
            unmatchedConsumption,
          }
        }
      }
    }
  `;

export const consumerMatchingOverTimeQuery = gql`
    query consumerMatchingOvertime($startDate: DateTime!, $endDate: DateTime!, $consumerIds: [UUID!], $groupBy: GroupBy!, $timeDefinition: TimeDefinition!, $limit: Int) {
      consumerKpi(startTime: $startDate, endTime: $endDate, consumerUuids: $consumerIds, timeDefinition: $timeDefinition, limit: $limit) {
        overTimeMatching(groupBy: $groupBy) {
          unit,
          interval,
          data {
            date,
            consumption,
            unmatchedConsumption,
            matchingAllocationsBreakdown {
              label,
              value
            },
            excessAllocationsBreakdown {
              label
              value
            }
          }
        }
      }
    }
  `;

export const consumerLongRunAverageMatchingQuery = gql`
    query consumerMatchingAverage($startDate: DateTime!, $endDate: DateTime!, $consumerIds: [UUID!], $groupBy: GroupBy!, $timeDefinition: TimeDefinition!, $limit: Int) {
      consumerKpi(startTime: $startDate, endTime: $endDate, consumerUuids: $consumerIds, timeDefinition: $timeDefinition, limit: $limit) {
        longRunAverageMatching(groupBy: $groupBy) {
          unit,
          timeAttribute,
          data {
            timeIndex,
            consumption,
            unmatchedConsumption,
            matchingAllocationsBreakdown {
              label,
              value
            },
            excessAllocationsBreakdown {
              label
              value
            }
          }
        }
      }
    }
  `;

export const consumerMatchingDevicesQuery = gql`
    query consumerMatchingDevices($startDate: DateTime!, $endDate: DateTime!, $consumerIds: [UUID!], $timeDefinition: TimeDefinition!) {
      consumerKpi(startTime: $startDate, endTime: $endDate, consumerUuids: $consumerIds, timeDefinition: $timeDefinition) {
        matchingDevices {
          devices {
            assetName,
            percentageMatchingGenerationAllocated,
            deviceTechnology,
            country,
            coordinates {
              longitude,
              latitude,
            }
          }
          total {
            percentageMatchingGenerationAllocated
          }
        }
      }
    }
  `;
